<template>
  <div class="home">
    <!-- <BaseHeaderBar :showBackButton="true" color="#333" bgColor="#FFF" /> -->
    <div class="product-list">
      <div v-for="item in productList" :key="item.id">
        <div class="title">
          <img :src="item.icon" :alt="item.name" />
          <p>{{ item.name }}</p>
          <a :href="item.down_url" target="_blank">Segera Pinjaman</a>
        </div>
        <div class="intro">
          <div>
            <p>Limit pinjaman：</p>
            <p>{{ item.loan_amount }}</p>
          </div>
          <div>
            <p>Tenor pinjaman：</p>
            <p>{{ item.loan_term }}</p>
          </div>
          <div>
            <p>Bunga/tahun：</p>
            <p>{{ item.annualized_interest_rate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
import request from '@/utils/request'

Vue.use(Toast)

export default {
  name: 'Home',
  data () {
    return {
      productList: []
    }
  },
  created () {
    this.getProductList()
  },
  methods: {
    getProductList () {
      request.post('getproductbak')
        .then(res => {
          this.productList = res.list
        })
    }
  }
}
</script>

<style scoped lang="less">
.home {
  background-color: #FFF;
}

.product-list {
  padding: 20px 30px 50px;

  & > div {
    border-radius: 8px;
    border: 1px solid #979797;

    &:nth-child(n+2) {
      margin-top: 12px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #979797;

    img {
      width: 92px;
      height: 92px;
    }

    p {
      flex: 1;
      margin-left: 12px;
      font-size: 30px;
      color: #333;
    }

    a {
      width: 309px;
      height: 88px;
      line-height: 88px;
      border-radius: 8px;
      font-size: 28px;
      color: #FFF;
      text-align: center;
      background-color: #3ACEF0;
    }
  }

  .intro {
    padding: 8px 12px;

    & > div {
      display: flex;
      justify-content: space-between;

      &:nth-child(n+2) {
        margin-top: 8px;
      }

      p {
        line-height: 42px;
        font-size: 30px;
        color: #333;
      }
    }
  }
}
</style>
